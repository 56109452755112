import React, { useState } from 'react';
import { CloseButton, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

import BeansCheckInForm from '../../components/beans-check-in-form/index.ts';
import ScanCheckInForm from '../../components/scan-check-in-form/index.ts';
import BetaBadge from '../../components/beta-badge/index.ts';

import './style.css';

const RecipeForm = () => (
  <h3>Recipe Form</h3>
);

const CheckInPage = () => {
  const defaultTab = 'beans-tab';
  const [activeTab, setActiveTab] = useState<string>(defaultTab);

  const history = useHistory();

  const closeForm = () => {
    history.goBack();
  };

  const handleTabSelect = (eventKey: any) => {
    console.log(`select tab: ${eventKey}`);
    setActiveTab(eventKey);
  };

  // TODO: block closing this page while submit is loading...
  const isSubmitLoading = false;

  return (
    <div className="checkInContainer">
      <CloseButton onClick={closeForm} disabled={isSubmitLoading} />
      <h3>Wat wil je in-checken?</h3>
      <Nav variant="tabs" defaultActiveKey={defaultTab} onSelect={handleTabSelect}>
        <Nav.Item>
          <Nav.Link eventKey="beans-tab">Bonen</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="scan-tab">
            <FontAwesomeIcon icon={faCamera} />
            &nbsp;
            Scan
            <BetaBadge hideVersion />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item hidden>
          <Nav.Link eventKey="recipe-tab">Recept</Nav.Link>
        </Nav.Item>
        <Nav.Item hidden>
          <Nav.Link eventKey="coffeebar-tab" disabled>
            Koffiebar
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {(activeTab === undefined || activeTab === 'beans-tab') && (
        <BeansCheckInForm />
      )}
      {activeTab === 'scan-tab' && <ScanCheckInForm />}
      {activeTab === 'recipe-tab' && <RecipeForm />}
    </div>
  );
};

export default CheckInPage;
