export const brewingMethods: Record<string, string> = {
  espresso: 'Espresso',
  filter: 'Filter',
  bialetti: 'Bialetti',
  other: 'Anders',
};

export type RoastType = 'espresso' | 'filter' | 'other';

export const roastTypes: Record<RoastType, string> = {
  espresso: 'Espresso Roast',
  filter: 'Filter Roast',
  other: 'Overig',
};

export enum CheckInType {
  BEANS = 'beans',
  // legacy check-in type.
  NONE = '',
}
