import { RoastType } from './constants.ts';

const baseUrl = process.env.REACT_APP_API_URL;

export const feedAPI = `${baseUrl}/feed`;
export const feedDetailAPI = (feedId: number) => `${baseUrl}/feed/${feedId}`;
export const brandDetailAPI = (brandId: number) => `${baseUrl}/brand/${brandId}`;
export const coffeeDetailAPI = (coffeeId: number) => `${baseUrl}/coffee/${coffeeId}`;
export const searchBrandsAPI = (query: string) => `${baseUrl}/search/brand?q=${encodeURIComponent(query)}`;
export const searchCoffeesAPI = (query: string) => `${baseUrl}/search/coffee?q=${encodeURIComponent(query)}`;
export const coffeeSuggestionAPI = (query: string) => `${baseUrl}/coffee/suggest?name=${encodeURIComponent(query)}`;
export const newCoffeeAPI = `${baseUrl}/coffee`;
export const signupAPI = `${baseUrl}/user/signup`;
export const authLoginAPI = `${baseUrl}/auth/login`;

// API V2 endpoints
const baseUrlV2 = process.env.REACT_APP_API_V2_URL;

export const focusAPI = `${baseUrlV2}/focus`;
export const setFocusAPI = (feedId: number) => `${baseUrlV2}/focus/${feedId}`;
export const createRecipeAPI = (feedId: number) => `${baseUrlV2}/recipe/${feedId}`;
export const scanAPI = `${baseUrlV2}/scan`;
export const checkInAPI = (feedId: number) => `${baseUrlV2}/check-in/${feedId}`;

const buildAuthHeader = (authToken: string) => ({
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
});

export const fetchFeed = (authToken: string) => {
  console.log('fetch feed');
  const authHeader = buildAuthHeader(authToken);
  return fetch(feedAPI, authHeader);
};

export interface FeedDetailType {
  brandId?: number;
  brandName?: string;
  coffeeId?: number;
  coffeeName: string;
  coffeeBrewMethod?: string;
  coffeeRating?: number;
  averageRating?: number;
  comment?: string;
  coffeeDateAdd: string;
  username: string;
  imageUrl?: string;
  productImageUrl?: string;
  brandImageUrl?: string;
  proSettings?: any;
  roastDate?: string;
  roastType: RoastType;
}

export const fetchFeedDetail = (authToken: string, feedId: number) => {
  console.log(`fetch feed with id: ${feedId}`);
  const authHeader = buildAuthHeader(authToken);
  return fetch(feedDetailAPI(feedId), authHeader);
};

export const fetchBrandDetail = (authToken: string, brandId: number) => {
  console.log(`fetch brand with id: ${brandId}`);
  const authHeader = buildAuthHeader(authToken);
  return fetch(brandDetailAPI(brandId), authHeader);
};

export const fetchCoffeeDetail = (authToken: string, coffeeId: number) => {
  console.log(`fetch coffee with id: ${coffeeId}`);
  const authHeader = buildAuthHeader(authToken);
  return fetch(coffeeDetailAPI(coffeeId), authHeader);
};

export const searchBrands = (authToken: string, query: string) => {
  console.log(`search brands by query: ${query}`);
  const authHeader = buildAuthHeader(authToken);
  return fetch(searchBrandsAPI(query), authHeader);
};

export const searchCoffees = (authToken: string, query: string) => {
  console.log(`search coffee by query: ${query}`);
  const authHeader = buildAuthHeader(authToken);
  return fetch(searchCoffeesAPI(query), authHeader);
};

export const fetchCoffeeSuggestions = (authToken: string, query: string) => {
  console.log(`search coffee suggestions by query: ${query}`);
  const authHeader = buildAuthHeader(authToken);
  return fetch(coffeeSuggestionAPI(query), authHeader);
};

export const submitNewCoffee = (authToken: string, data: any) => {
  console.log('submit new coffeee');
  const authHeader = buildAuthHeader(authToken);
  const requestOptions = {
    ...authHeader,
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetch(newCoffeeAPI, requestOptions);
};

export const submitUserRegistration = (data: any) => {
  console.log('submit new user registration');
  const requestOptions = {
    // call is not authenticated.
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetch(signupAPI, requestOptions);
};

export const login = (email: string, password: string) => {
  console.log(`submit login for email: ${email}`);
  const data = {
    email,
    password,
  };
  const requestOptions = {
    // call is not authenticated.
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetch(authLoginAPI, requestOptions);
};

export const fetchFocus = (authToken: string) => {
  console.log('fetch focus');
  const authHeader = buildAuthHeader(authToken);
  return fetch(focusAPI, authHeader);
};

export const setFocus = (authToken: string, feedId: number) => {
  console.log('set focus');
  const authHeader = buildAuthHeader(authToken);
  const requestOptions = {
    ...authHeader,
    method: 'POST',
  };
  return fetch(setFocusAPI(feedId), requestOptions);
};

export const resetFocus = (authToken: string) => {
  console.log('reset focus');
  const authHeader = buildAuthHeader(authToken);
  const requestOptions = {
    ...authHeader,
    method: 'DELETE',
  };
  return fetch(focusAPI, requestOptions);
};

export const createRecipe = (authToken: string, feedId: number, data: any) => {
  console.log(`create new recipe for feedId: ${feedId}`, data);
  const authHeader = buildAuthHeader(authToken);
  const requestOptions = {
    headers: {
      ...authHeader.headers,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetch(createRecipeAPI(feedId), requestOptions);
};

export const startImageScan = (authToken: string, imageData: string) => {
  const authHeader = buildAuthHeader(authToken);
  const body = {
    imageData,
  };
  const requestOptions = {
    headers: {
      ...authHeader.headers,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(body),
  };
  return fetch(scanAPI, requestOptions);
};

export const deleteCheckIn = (authToken: string, feedId: number) => {
  console.log(`delete check-in: ${feedId}`);
  const authHeader = buildAuthHeader(authToken);
  const requestOptions = {
    headers: {
      ...authHeader.headers,
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  };
  return fetch(checkInAPI(feedId), requestOptions);
};

export interface UpdateCheckInType {
  roastDate?: Date;
  roastType: RoastType;
  notes?: string;
}

export const updateCheckIn = (authToken: string, feedId: number, data: UpdateCheckInType) => {
  console.log(`update check-in: ${feedId}`);
  const authHeader = buildAuthHeader(authToken);
  const requestOptions = {
    headers: {
      ...authHeader.headers,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetch(checkInAPI(feedId), requestOptions);
};
