import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Container,
} from 'react-bootstrap';

import CoffeeDetail from '../../components/coffee-detail/index.ts';
import ImageGallery from '../../components/image-gallery/index.ts';
import FavoriteButton from '../../components/favorite-button/index.ts';
import ProSettings from '../../components/pro-settings/index.ts';
import PageTemplate from '../../components/page-template/index.ts';

import { useAuth, AuthContextType } from '../../context/Auth.tsx';
import { StoreContextType, useStore } from '../../store/store-context.tsx';
import { deleteCheckIn, FeedDetailType, fetchFeedDetail } from '../../services/api.ts';
import { setCheckIn } from '../../store/feed-reducer.ts';

import './style.css';

function useFetchData(feedId: number) {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<FeedDetailType | null>(null);
  const { authToken } = useAuth() as AuthContextType;
  const { dispatch } = useStore() as StoreContextType;

  useEffect(() => {
    setLoading(true);
    fetchFeedDetail(authToken!, feedId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json() as Promise<FeedDetailType>)
      .then((body) => {
        setLoading(false);
        setData(body);
        dispatch(setCheckIn(body));
      })
      .catch(() => {
        console.log('error!'); // TODO
      });
  }, [authToken, feedId, dispatch]);
  return {
    isLoading,
    data,
  };
}

const FeedDetail = () => {
  const { authToken } = useAuth() as AuthContextType;
  const { feedId } = useParams<{ feedId: string }>();
  const { isLoading, data } = useFetchData(Number(feedId));
  const history = useHistory();

  if (isLoading || data === null) {
    return <div>Loading...</div>;
  }

  const {
    coffeeName,
    coffeeId,
    coffeeRating,
    averageRating,
    comment,
    imageUrl,
    productImageUrl,
    brandImageUrl,
    brandName,
    brandId,
    coffeeBrewMethod,
    proSettings,
    roastDate,
    roastType,
  } = data!;

  const images = [];
  if (imageUrl) {
    images.push(imageUrl);
  }
  if (productImageUrl) {
    images.push(productImageUrl);
  }
  if (brandImageUrl) {
    images.push(brandImageUrl);
  }

  const handleDelete = async () => {
    await deleteCheckIn(authToken!, Number(feedId));
    history.goBack();
  };

  const actions = [];
  actions.push((
    <Button key="edit-action" variant="primary" onClick={() => history.push(`/edit/check-in/${feedId}`)}>
      Check-In bewerken
    </Button>
  ));
  actions.push((
    <Button key="delete-action" variant="outline-danger" onClick={() => handleDelete()}>
      Check-In verwijderen
    </Button>
  ));

  return (
    <PageTemplate title={coffeeName} actions={actions} className="feedDetail">
      <CoffeeDetail
        coffeeName={coffeeName}
        coffeeLink={coffeeId ? `/coffee/${coffeeId}` : undefined}
        brandName={brandName}
        brandLink={brandId ? `/brand/${brandId}` : undefined}
        brewMethod={coffeeBrewMethod}
        coffeeRating={coffeeRating}
        averageRating={averageRating}
        comment={comment}
        roastDate={roastDate ? new Date(roastDate) : undefined}
        roastType={roastType}
      />
      <Container className="proSettingsContainer">
        { proSettings && <ProSettings data={proSettings} />}
        { !proSettings && <span>(no PRO settings)</span>}
      </Container>
      <Container className="imageContainer">
        { images && (
        <ImageGallery images={images} />
        )}
      </Container>
      <Container className="button">
        <FavoriteButton
          feedId={parseInt(feedId, 10)}
        />
      </Container>
    </PageTemplate>
  );
};

export default FeedDetail;
