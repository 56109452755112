import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Modal,
  Button,
  Form,
  Spinner,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { AuthContextType, useAuth } from '../../context/Auth.tsx';
import { searchBrands, searchCoffees } from '../../services/api.ts';

import './style.css';

type BrandType = {
  id: number,
  name: string,
  imageUrl?: string,
}

type CoffeeType = {
  id: number,
  name: string,
  imageUrl?: string,
  brand: BrandType,
}

const useFetchData = () => {
  const [isLoading, setLoading] = useState(false);
  const [brandResults, setBrandResults] = useState<BrandType[]>([]);
  const [coffeeResults, setCoffeeResults] = useState<CoffeeType[]>([]);
  const { authToken } = useAuth() as AuthContextType;

  const fetch = async (query: string) => {
    setLoading(true);

    const [brands, coffees] = await Promise.all([
      // search by: brand
      searchBrands(authToken!, query)
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          return res;
        })
        .then((res) => res.json())
        .catch(() => {
          console.log('error!'); // TODO
        }),
      // search by: coffee
      searchCoffees(authToken!, query)
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          return res;
        })
        .then((res) => res.json())
        .catch(() => {
          console.log('error!'); // TODO
        }),
    ]);

    setBrandResults(brands);
    setCoffeeResults(coffees);
    setLoading(false);
  };

  return {
    fetch,
    isLoading,
    brandResults,
    coffeeResults,
  };
};

const BrandResult: React.FC<{ brand: BrandType }> = (props) => {
  const { brand } = props;
  return (
    <div key={`brand-${brand.id}`} className="search-result">
      <Link to={`/brand/${brand.id}`}>
        <strong>{brand.name}</strong>
      </Link>
    </div>
  );
};

const CoffeeResult: React.FC<{ brand: BrandType, coffee: CoffeeType }> = (props) => {
  const { brand, coffee } = props;
  return (
    <div key={`coffee-${coffee.id}`} className="search-result">
      <Link to={`/coffee/${coffee.id}`}>
        <strong>{coffee.name}</strong>
      </Link>
      <div>{brand.name}</div>
    </div>
  );
};

const Search = () => {
  const [query, setQuery] = useState('');
  const {
    fetch, isLoading, brandResults, coffeeResults,
  } = useFetchData();
  const history = useHistory();

  const doSearch = (event: any) => {
    event.preventDefault();
    fetch(query);
  };

  return (
    <Modal className="search-modal" show fullscreen>
      <Modal.Header>
        <Modal.Title>
          <Button variant="outline-primary" aria-label="Back" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <Form onSubmit={doSearch}>
            <Form.Control
              type="keywords"
              placeholder="Zoek op merk of koffie..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Form>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { isLoading && <Spinner animation="border" size="sm" /> }
        { !isLoading && (
        <div>
          {brandResults.map((brand) => (
            <BrandResult key={`brand-${brand.id}`} brand={brand} />
          ))}
          {coffeeResults.map((coffee) => (
            <CoffeeResult key={`coffee-${coffee.id}`} brand={coffee.brand} coffee={coffee} />
          ))}
          { query === 'devtoolsxp' && <Link to="/xp">Experiments Dashboard</Link> }
        </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Search;
