import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import './style.css';

interface ExternalLinkProps {
  url: string,
  title: string,
}

export const ExternalLink = (props: ExternalLinkProps) => {
  const {
    url,
    title,
  } = props;

  return (
    <a href={url} rel="noreferrer" target="_blank" className="externalLink">
      <FontAwesomeIcon icon={faUpRightFromSquare} />
      &nbsp;
      {title}
    </a>
  );
};

export default ExternalLink;
