import React, { SyntheticEvent, useState } from 'react';
import {
  Form,
  Button,
  Col,
  Row,
  Alert,
} from 'react-bootstrap';

import { ProSettingsData } from '../pro-settings/index.ts';
import { AuthContextType, useAuth } from '../../context/Auth.tsx';
import { StoreContextType, useStore } from '../../store/store-context.tsx';
import { createRecipe } from '../../services/api.ts';
import { setFavoriteProSettings } from '../../store/feed-reducer.ts';

import './style.css';

const useSubmitData = (feedId: number) => {
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState<undefined | Error>(undefined);
  const { authToken } = useAuth() as AuthContextType;
  const { dispatch } = useStore() as StoreContextType;

  const submitData = (data: any) => {
    setSubmitLoading(true);
    setSubmitSuccess(false);
    setSubmitError(undefined);
    createRecipe(authToken!, feedId, data)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        console.log('new recipe response', body);
        setSubmitLoading(false);
        setSubmitSuccess(true);
        dispatch(setFavoriteProSettings(body));
      })
      .catch((err: Error) => {
        setSubmitLoading(false);
        setSubmitError(err);
        console.log('save pro-settings failed', err);
      });
  };

  return {
    isSubmitLoading,
    isSubmitSuccess,
    submitError,
    submitData,
  };
};

interface ProSettingsFormProps {
  feedId: number,
  data: ProSettingsData,
}

const ProSettingsForm = (props: ProSettingsFormProps) => {
  const {
    feedId,
    data,
  } = props;
  const {
    isSubmitLoading, isSubmitSuccess, submitError, submitData,
  } = useSubmitData(feedId);

  // form fields
  const [grindSize, setGrindSize] = useState<number| undefined>(data.grindSize);
  const [doseIn, setDoseIn] = useState<number| undefined>(data.doseIn);
  const [yieldOut, setYieldOut] = useState<number| undefined>(data.yieldOut);
  const [extractionTime, setExtractionTime] = useState<number| undefined>(data.extractionTime);
  const [notes, setNotes] = useState<string| undefined>(data.notes);

  const handleFormSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const recipe = {
      // feedId: data.feedId,
      grindSize,
      doseIn,
      yieldOut,
      extractionTime,
      notes,
    };
    submitData(recipe);
  };

  return (
    <Form className="proSettingsForm" onSubmit={handleFormSubmit}>

      <Form.Group as={Row} className="mb-3" controlId="formGrindSize">
        <Col>
          <Form.Label as={Col}>Maalstand</Form.Label>
          <Form.Text id="formGrindSizeHelp" muted>
            Welke maalstand heb je gebruikt op je koffie-molen?
          </Form.Text>
        </Col>
        <Col xs={3}>
          <Form.Control type="number" value={grindSize} onChange={(e) => setGrindSize(parseInt(e.target.value, 10))} />
        </Col>
        <Col xs={2} />
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formDose">
        <Col>
          <Form.Label as={Col}>Dosis</Form.Label>
          <Form.Text id="formDoseHelp" muted>
            Gewicht van de hoeveelheid gemalen koffie.
          </Form.Text>
        </Col>
        <Col xs={3}>
          <Form.Control
            type="number"
            step="0.1"
            value={doseIn}
            onChange={(e) => setDoseIn(parseFloat(e.target.value))}
          />
        </Col>
        <Col xs={2}>gr.</Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formYield">
        <Col>
          <Form.Label as={Col}>Yield</Form.Label>
          <Form.Text id="formYieldHelp" muted>
            Gewicht van de koffie-shot.
          </Form.Text>
        </Col>
        <Col xs={3}>
          <Form.Control
            type="number"
            step="0.1"
            value={yieldOut}
            onChange={(e) => setYieldOut(parseFloat(e.target.value))}
          />
        </Col>
        <Col xs={2}>gr.</Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formExtractionTime">
        <Col>
          <Form.Label as={Col}>Tijd</Form.Label>
          <Form.Text id="formExtractionTimeHelp" muted>
            Extractie tijd, gemeten vanaf het moment dat de machine aan gaat.
          </Form.Text>
        </Col>
        <Col xs={3}>
          <Form.Control
            type="number"
            value={extractionTime}
            onChange={(e) => setExtractionTime(parseInt(e.target.value, 10))}
          />
        </Col>
        <Col xs={2}>sec.</Col>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formNotes">
        <Form.Label>Notities</Form.Label>
        <Form.Control as="textarea" rows={3} value={notes} onChange={(e) => setNotes(e.target.value)} />
      </Form.Group>

      <div className="buttons">
        { submitError && (
          <Alert variant="danger">
            Oplsaan mislukt, probeer het later nog een keer.
            <div className="errorDetail">
              <strong>Detail:</strong>
              &nbsp;
              {submitError.message}
            </div>
          </Alert>
        )}
        { isSubmitSuccess && <Alert variant="success">PRO-settings zijn opgeslagen</Alert> }
        <Button variant="primary" type="submit" disabled={isSubmitLoading}>
          {!isSubmitLoading && <span>Opslaan</span>}
          {isSubmitLoading && <span>Bezig met opslaan...</span>}
        </Button>
      </div>
    </Form>
  );
};

export default ProSettingsForm;
