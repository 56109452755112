import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Form,
  Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { RoastType, roastTypes } from '../../services/constants.ts';
import { useAuth, AuthContextType } from '../../context/Auth.tsx';
import { StoreContextType, useStore } from '../../store/store-context.tsx';
import { updateCheckIn, UpdateCheckInType } from '../../services/api.ts';
import PageTemplate from '../../components/page-template/index.ts';

import './style.css';

const withTimer = (handler: () => void) => {
  const timer = setTimeout(handler, 500);
  return () => clearTimeout(timer);
};

const useSubmit = (mockSaving: boolean, mockSaved: boolean, mockError: boolean) => {
  const [isSaving, setSaving] = useState<boolean>(mockSaving);
  const [isSaved, setSaved] = useState<boolean>(mockSaved);
  const [isError, setError] = useState<boolean>(mockError);

  const { authToken } = useAuth() as AuthContextType;

  const submit = (feedId: number, data: UpdateCheckInType) => {
    setSaving(true);
    setSaved(false);
    setError(false);
    updateCheckIn(authToken!, feedId, data)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        // TODO: handle response
        setSaving(false);
        setSaved(true);
      })
      .catch((e: Error) => {
        console.error(e);
        setSaving(false);
        setError(true);
      });
  };

  return {
    submit,
    isSaving,
    isSaved,
    isError,
  };
};

interface EditCheckInPageProps {
  mockSaving?: boolean;
  mockSaved?: boolean;
  mockError?: boolean;
}

const EditCheckInPage = (props: EditCheckInPageProps) => {
  const { mockSaving = false, mockSaved = false, mockError = false } = props;

  const {
    isSaving,
    isSaved,
    isError,
    submit,
  } = useSubmit(mockSaving, mockSaved, mockError);

  const { state: { checkIn } } = useStore() as StoreContextType;

  const coffeeName = checkIn?.coffeeName;
  const brandName = checkIn?.brandName;
  const defaultRoastDate = checkIn?.roastDate ? checkIn.roastDate.split('T')[0] : '';
  const defaultRoastType = checkIn?.roastType;

  const [roastDate, setRoastDate] = useState<string>(defaultRoastDate);
  const [roastType, setRoastType] = useState<string | undefined>(defaultRoastType);
  const [comment, setComment] = useState<string | undefined>(checkIn?.comment);

  const [validated, setValidated] = useState<boolean>(false);

  const { feedId } = useParams<{ feedId: string }>();
  const history = useHistory();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      submit(Number(feedId), {
        roastDate: roastDate ? new Date(roastDate) : undefined,
        roastType: roastType as RoastType,
        notes: comment !== '' ? comment : undefined,
      });
    }
    setValidated(true);
  };

  const handleResetDate = (event: any) => {
    console.log('reset roast-date');
    event.preventDefault();
    event.stopPropagation();
    setRoastDate('');
  };

  if (isSaved) {
    withTimer(() => history.goBack());
  }

  const locked = isSaving || isSaved;

  return (
    <PageTemplate title="Check-In bewerken" className="editCheckInPage">
      <div>
        {brandName}
        <h3>{coffeeName}</h3>
      </div>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Form.Group className="group">
          <Form.Label>Wat is de brand datum?</Form.Label>
          <Form.Control
            type="date"
            onChange={(e) => setRoastDate(e.target.value)}
            value={roastDate}
            disabled={locked}
            aria-describedby="roastDateHelpBlock"
          />
          <Form.Text id="roastDateHelpBlock" muted>
            Geen brand datum? Wis dan
            &nbsp;
            <button type="button" className="link" onClick={(e) => handleResetDate(e)}>hier</button>
            &nbsp;
            de datum.
          </Form.Text>
        </Form.Group>
        <Form.Group className="group">
          <Form.Label>Wat de branding?</Form.Label>
          <Form.Control
            as="select"
            value={roastType}
            onChange={(e) => setRoastType(e.target.value)}
            disabled={locked}
          >
            <option key="0" value="">Selecteer branding</option>
            {
              Object.entries(roastTypes).map(
                ([code, title]) => <option key={code} value={code}>{title}</option>,
              )
            }
          </Form.Control>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Notitie</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={comment}
            disabled={locked}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Group>
        { isError && (
          <div className="formRow">
            <Alert variant="danger">Wijzigen mislukt. Probeer het later nog een keer.</Alert>
            <div>{isError}</div>
          </div>
        )}
        <div className="formRow">
          {isSaved && (
            <div className="saved">
              <FontAwesomeIcon icon={faCheck} />
              &nbsp;
              Wijzigingen opgeslagen!
            </div>
          )}
          {!isSaved && (
            <Button type="submit" disabled={locked}>
              {!isSaving && <>Opslaan</>}
              {isSaving && (
                <>
                  <Spinner animation="border" size="sm" />
                  &nbsp;
                  <span>Wijzingen opslaan...</span>
                </>
              )}
            </Button>
          )}
          <Button
            className="cancel"
            variant="outline-danger"
            disabled={locked}
            onClick={() => history.goBack()}
          >
            Annuleren
          </Button>
        </div>
      </Form>
    </PageTemplate>
  );
};

export default EditCheckInPage;
