import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Spinner,
} from 'react-bootstrap';

import CoffeeDetail from '../../components/coffee-detail/index.ts';
import FavoriteButton from '../../components/favorite-button/index.ts';
import ProSettings from '../../components/pro-settings/index.ts';
import { StoreContextType, useStore } from '../../store/store-context.tsx';
import PageTemplate from '../../components/page-template/index.ts';

import './style.css';

const FocusPage = () => {
  const [isLoading] = useState<boolean>(false);
  const { state: { favorite } } = useStore() as StoreContextType;

  const history = useHistory();

  if (!favorite) {
    console.log('favorite not set; navigating back');
    history.goBack();
    // empty div to prevent rendering the full component.
    return <div />;
  }

  const actions = [];
  if (favorite.proSettings) {
    actions.push((
      <Button onClick={() => history.push('/pro-settings')}>
        Wijzig PRO Settings
      </Button>
    ));
  }
  actions.push((
    <FavoriteButton
      secondary
      feedId={favorite.feedId}
    />
  ));

  return (
    <PageTemplate title="Jouw koffie" className="focusPage">
      { isLoading && (
        <div className="body">
          <Spinner animation="border" size="sm" />
        </div>
      )}
      { !isLoading && favorite && (
        <div className="body">
          <CoffeeDetail
            coffeeName={favorite.coffeeName}
            brandName={favorite.brandName}
            brewMethod="espresso"
            brandLink={favorite.brandId ? `/brand/${favorite.brandId}` : undefined}
            coffeeLink={favorite.coffeeId ? `/coffee/${favorite.coffeeId}` : undefined}
          />
          { favorite.proSettings && <ProSettings data={favorite.proSettings} /> }
          { !favorite.proSettings && (
            <div className="actionButtons">
              <Button onClick={() => history.push('/pro-settings')}>
                PRO Settings Toevoegen
              </Button>
            </div>
          )}
        </div>
      )}
    </PageTemplate>
  );
};

export default FocusPage;
