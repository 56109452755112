import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Image,
  Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { AuthContextType, useAuth } from '../../context/Auth.tsx';
import { fetchCoffeeDetail } from '../../services/api.ts';
import ExternalLink from '../../components/external-link/index.ts';

import './style.css';

interface CoffeeInfoResponseType {
  brandId: number;
  brandName: string;
  coffeeName: string;
  coffeeImageUrl?: string;
  coffeeRoast: string;
  coffeeOrigin?: string;
  coffeeRegion?: string;
  coffeeProcessing?: string;
  coffeeVariety?: string;
  coffeeTaste?: string;
  coffeeWebsite?: string;
}

function useFetchData() {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<CoffeeInfoResponseType | null>(null);
  const { authToken } = useAuth() as AuthContextType;
  const { coffeeId } = useParams<{ coffeeId: string }>();
  useEffect(() => {
    setLoading(true);
    fetchCoffeeDetail(authToken!, Number.parseInt(coffeeId, 10))
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        setLoading(false);
        setData(body);
      })
      .catch(() => {
        console.log('error!'); // TODO
      });
  }, [authToken, coffeeId]);
  return {
    isLoading,
    data,
  };
}

const CoffeeDetail = () => {
  const { isLoading, data } = useFetchData();
  const history = useHistory();

  const {
    coffeeName,
    brandName,
    coffeeRoast,
    coffeeOrigin,
    coffeeRegion,
    coffeeProcessing,
    coffeeVariety,
    coffeeTaste,
    coffeeWebsite,
    coffeeImageUrl,
  } = data || {};

  return (
    <Modal show fullscreen>
      <Modal.Header>
        <Modal.Title>
          <Button variant="outline-primary" aria-label="Back" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} />
            { !isLoading && (
              <span>
                &nbsp;
                {coffeeName}
              </span>
            )}
          </Button>
        </Modal.Title>
      </Modal.Header>
      { isLoading && (
        <Modal.Body className="coffeePage">
          <Spinner animation="border" />
        </Modal.Body>
      )}
      { !isLoading && (
        <Modal.Body className="coffeePage">
          <Container>
            <Row>
              <Col>
                <div>
                  <div className="label">Merk</div>
                  <div className="value">{brandName}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="label">Branding</div>
                  <div className="value">{coffeeRoast}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <div className="label">Oorsprong</div>
                  <div className="value">
                    {(coffeeOrigin || coffeeRegion) && (
                      <span>
                        {coffeeOrigin}
                        {coffeeRegion}
                      </span>
                    )}
                    {!coffeeOrigin && !coffeeRegion && '-'}
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="label">Processing</div>
                  <div className="value">{coffeeProcessing || '-'}</div>
                </div>
              </Col>
            </Row>
            {coffeeVariety && (
            <Row>
              <Col>
                <div>
                  <div className="label">Variety</div>
                  <div className="value">
                    {coffeeVariety}
                  </div>
                </div>
              </Col>
            </Row>
            )}
            {coffeeTaste && (
            <Row>
              <Col>
                <div>
                  <div className="label">Smaakprofiel</div>
                  <div className="value">{coffeeTaste}</div>
                </div>
              </Col>
            </Row>
            )}
            {coffeeWebsite && (
            <Row>
              <Col>
                <ExternalLink
                  url={coffeeWebsite}
                  title={coffeeName}
                />
              </Col>
            </Row>
            )}
            {coffeeImageUrl && (
            <Row className="image">
              <Image src={coffeeImageUrl} />
            </Row>
            )}
          </Container>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default CoffeeDetail;
