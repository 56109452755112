import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Offcanvas,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEllipsis } from '@fortawesome/free-solid-svg-icons';

import './style.css';

type ReactText = string | number;
type ReactChild = ReactElement | ReactText;

interface ReactNodeArray extends Array<ReactNode> {}
type ReactFragment = {} | ReactNodeArray;
type ReactNode = ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;

interface PageTemplateProps {
  title: string;
  children: ReactNode;
  actions?: JSX.Element | JSX.Element[];
  className?: string;
}

const PageTemplate = (props: PageTemplateProps) => {
  const {
    title, children, actions, className = '',
  } = props;
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const history = useHistory();

  // TODO: add loader support; is this possible?

  return (
    <div className={`pageTemplate ${className}`}>
      <div className="header">
        <Button variant="outline-primary" aria-label="Back" onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <h3>
          {title}
        </h3>
        <div className="action">
          {actions && (
          <FontAwesomeIcon icon={faEllipsis} size="2xl" onClick={() => setShowMenu(true)} />
          )}
        </div>
      </div>
      <div className="body">
        {children}
      </div>
      <Offcanvas show={showMenu} placement="bottom">
        <Offcanvas.Header closeButton onHide={() => setShowMenu(false)} />
        <Offcanvas.Body className="actionButtons">
          {actions}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default PageTemplate;
