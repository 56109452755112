import React, { useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';

import { AuthContextType, useAuth } from '../../context/Auth.tsx';
import { StoreContextType, useStore } from '../../store/store-context.tsx';
import { addCoffee } from '../../store/feed-reducer.ts';
import { startImageScan, submitNewCoffee } from '../../services/api.ts';
import TakePhoto from '../take-photo/take-photo.tsx';
import { CheckInType } from '../../services/constants.ts';

import './style.css';

interface ScanSuggestionType {
  coffeeId: number;
  coffeeName: string;
  brandId: number;
  brandName: string;
}

interface ScanResultType {
  detectedText: string[];
  suggestions: ScanSuggestionType[];
}

const useScanning = (mockScanResult?: ScanResultType) => {
  const [isScanning, setScanning] = useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const [isSubmitError, setSubmitError] = useState(false);
  const [scanResult, setScanResult] = useState<ScanResultType | undefined>(mockScanResult);
  const { authToken } = useAuth() as AuthContextType;
  // const { dispatch } = useStore() as StoreContextType;

  const startScan = (imageData: string) => {
    setScanning(true);
    setSubmitSuccess(false);
    setSubmitError(false);
    startImageScan(authToken!, imageData)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        console.log('scan response', body);
        setScanning(false);
        setSubmitSuccess(true);
        setScanResult(body as ScanResultType);
        // const mock = body as ScanResultType;
        // mock.suggestions = [];
        // mock.detectedText = [
        //   'hoi', 'dit', 'zijn', 'allerlei', 'random', 'woorden',
        //   'en', 'hier', 'nog', 'veel', 'meer', 'tekst',
        // ];
        // setScanResult(mock);
      })
      .catch(() => {
        setScanning(false);
        setSubmitError(true);
        console.log('error!'); // TODO
      });
  };

  const cancelScan = () => {
    setScanning(false);
    setScanResult(undefined);
  };

  return {
    isScanning,
    isSubmitSuccess,
    isSubmitError,
    startScan,
    cancelScan,
    scanResult,
  };
};

const useSubmitData = () => {
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const [isSubmitError, setSubmitError] = useState(false);
  const { authToken } = useAuth() as AuthContextType;
  const { dispatch } = useStore() as StoreContextType;

  const submitData = (data: any) => {
    setSubmitLoading(true);
    setSubmitSuccess(false);
    setSubmitError(false);
    submitNewCoffee(authToken!, data)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        console.log('new coffee response', body);
        setSubmitLoading(false);
        setSubmitSuccess(true);
        dispatch(addCoffee(body));
      })
      .catch(() => {
        setSubmitLoading(false);
        setSubmitError(true);
        console.log('error!'); // TODO
      });
  };

  return {
    isSubmitLoading,
    isSubmitSuccess,
    isSubmitError,
    submitData,
  };
};

const renderSuggestions = (result: ScanResultType, cancelScan: any, handleCheckIn: any) => (
  <div className="suggestionsContainer">
    <p>
      Yeaaah, we hebben een (mogelijke) match gevonden!
      Is dit wat je zocht?
    </p>
    {result.suggestions.map((item, i) => (
      <Button
        key={`coffee-${item.coffeeId}`}
        variant="outline-secondary"
        onClick={() => handleCheckIn(item.coffeeId)}
        className="suggestion"
      >
        <div>{item.brandName}</div>
        <strong>{item.coffeeName}</strong>
      </Button>
    ))}
    <div>
      <Button variant="outline-primary" size="lg" onClick={() => cancelScan()}>
        Scan Opnieuw
      </Button>
    </div>
  </div>
);

const renderNoMatch = (detectedText: string[], cancelScan: any) => (
  <div className="noMatchContainer">
    <p>
      Helaas geen match gevonden. We hebben gezocht op:
    </p>
    <div className="textContainer">
      {detectedText.map((item, i) => (
        <div key={item} className="textItem">{item}</div>
      ))}
    </div>
    <p>
      ...maar niks gevonden!
    </p>
    <div>
      <Button variant="outline-primary" size="lg" onClick={() => cancelScan()}>
        Scan Opnieuw
      </Button>
    </div>
  </div>
);

interface LoaderProps {
  text: string;
}

const Loader = ({ text }: LoaderProps) => (
  <div className="buttonSpinner">
    <Spinner animation="border" size="sm" />
    <span>{text}</span>
  </div>
);

interface ScanCheckInFormProps {
  mockScanResult?: ScanResultType;
  mockIsScanning?: boolean;
  mockIsSubmitLoading?: boolean;
  mockIsSubmitSuccess?: boolean;
}

const ScanCheckInForm = (props: ScanCheckInFormProps) => {
  const {
    mockScanResult,
    mockIsScanning = false,
    mockIsSubmitLoading = false,
    mockIsSubmitSuccess = false,
  } = props;
  const {
    isScanning, startScan, cancelScan, scanResult,
  } = useScanning(mockScanResult);
  const {
    isSubmitLoading, isSubmitSuccess, submitData,
  } = useSubmitData();

  const handleCheckIn = (coffeeId: number) => {
    console.log(`check-in for: ${coffeeId}`);
    const data = {
      coffeeId,
      // brewMethod: coffeeBrewMethod,
      // rating: coffeeRating,
      // comment: coffeeComment,
      // imageData: coffeeImageData,
      checkInType: CheckInType.BEANS,
      roastType: 'espresso', // TODO: remove hardcoded value.
      // roastDate,
    };
    submitData(data);
  };

  let content;
  if (mockIsSubmitSuccess || isSubmitSuccess) {
    content = (
      <Alert variant="success">
        Je koffie is in-gecheckt!
        <h2>🎉</h2>
      </Alert>
    );
  } else if (mockIsSubmitLoading || isSubmitLoading) {
    content = (
      <Loader text="Bezig met versturen..." />
    );
  } else if (!mockIsScanning && !isScanning && !scanResult) {
    content = (
      <div>
        <p>
          Maak een
          &nbsp;
          <strong>foto</strong>
          &nbsp;
          van het
          &nbsp;
          <strong>koffie label</strong>
          &nbsp;
          om te starten met scannen.
        </p>
        <div className="photoContainer">
          <TakePhoto onPhotoTaken={(data) => startScan(data)} />
        </div>
      </div>
    );
  } else if (mockIsScanning || isScanning) {
    content = (
      <div className="scanningContainer">
        <Loader text="Bezig met scannen..." />
        <Button variant="outline-danger" size="sm" onClick={() => cancelScan()}>
          Annuleren
        </Button>
      </div>
    );
  } else if (scanResult && scanResult.suggestions.length > 0) {
    content = renderSuggestions(scanResult, cancelScan, handleCheckIn);
  } else if (scanResult && scanResult.suggestions.length === 0) {
    content = renderNoMatch(scanResult.detectedText, cancelScan);
  }

  return (
    <div className="scanForm">
      {content}
    </div>
  );
};

export default ScanCheckInForm;
