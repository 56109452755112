import React from 'react';

import './style.css';

interface BetaBadgeProps {
  hideVersion?: boolean;
}

const BetaBadge = (props: BetaBadgeProps) => {
  const { hideVersion = false } = props;
  const version = process.env.STORYBOOK ? '(no version)' : `${GIT_HASH}`;
  return (
    <div className="beta-badge">
      <div className="label">BETA</div>
      {!hideVersion && <span className="git">{version}</span>}
    </div>
  );
};

export default BetaBadge;
