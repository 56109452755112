import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Image,
  Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Rating from '../../components/rating/index.ts';
import ProductCard from '../../components/product-card/index.ts';
import ExternalLink from '../../components/external-link/index.ts';
import { AuthContextType, useAuth } from '../../context/Auth.tsx';
import { fetchBrandDetail } from '../../services/api.ts';

import './style.css';

interface ProductItemType {
  coffeeId: number;
  coffeeName: string;
  coffeeImageUrl?: string;
}

interface BrandDetailResponseType {
  brandName: string;
  brandLocation?: string;
  brandBio?: string;
  brandAverageRating: number;
  brandImageUrl?: string;
  brandWebsite?: string;
  products: ProductItemType[];
}

function useFetchData() {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<BrandDetailResponseType | null>(null);
  const { authToken } = useAuth() as AuthContextType;
  const { brandId } = useParams<{brandId: string}>();
  useEffect(() => {
    setLoading(true);
    fetchBrandDetail(authToken!, Number.parseInt(brandId, 10))
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        setLoading(false);
        setData(body);
      })
      .catch(() => {
        console.log('error!'); // TODO
      });
  }, [authToken, brandId]);
  return {
    isLoading,
    data,
  };
}

const BrandDetail = () => {
  const { isLoading, data } = useFetchData();
  const history = useHistory();

  const {
    brandName,
    brandLocation = 'The World',
    brandBio,
    brandAverageRating,
    brandImageUrl,
    brandWebsite,
    products,
  } = data || {};

  return (
    <Modal show fullscreen>
      <Modal.Header>
        <Modal.Title>
          <Button variant="outline-primary" aria-label="Back" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} />
            { !isLoading && (
              <span>
                &nbsp;
                {brandName}
              </span>
            )}
          </Button>
        </Modal.Title>
      </Modal.Header>
      { isLoading && (
        <Modal.Body className="brandDetail">
          <Spinner animation="border" />
        </Modal.Body>
      )}
      { !isLoading && (
        <Modal.Body className="brandDetail">
          <Container>
            <Row>
              <Col>
                <div>
                  <div className="label">Merk</div>
                  <div className="value">{brandName}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="label">Gevestigd</div>
                  <div className="value">{brandLocation}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <div className="label">Soorten Koffie</div>
                  <div className="value">{products && products.length}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="label">Gemiddelde Score</div>
                  <div className="value">
                    <Rating
                      rating={brandAverageRating}
                      readOnly
                    />
                    <span className="rating small">{brandAverageRating}</span>
                  </div>
                </div>
              </Col>
            </Row>
            {brandBio && (
            <Row>
              <Col>
                <div>
                  <div className="label">Bio</div>
                  <div className="value">{brandBio}</div>
                </div>
              </Col>
            </Row>
            )}
            {brandWebsite && (
            <Row>
              <Col>
                <ExternalLink
                  url={brandWebsite}
                  title={`Ga naar de website van ${brandName}`}
                />
              </Col>
            </Row>
            )}
            <Row className="logo">
              <Image src={brandImageUrl} />
            </Row>
            <Row>
              <Col>
                <div>
                  <div className="label">Onze Koffies</div>
                </div>
              </Col>
            </Row>
            {products && (
              <Row className="products">
                {products.map((item) => (
                  <Link to={`/coffee/${item.coffeeId}`} key={item.coffeeId}>
                    <ProductCard
                      id={item.coffeeId}
                      title={item.coffeeName}
                      imageUrl={item.coffeeImageUrl}
                    />
                  </Link>
                ))}
              </Row>
            )}
          </Container>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default BrandDetail;
